import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './PaymentReturn.css';
import { usePostOrderStatus } from '../../hooks/useGetOrderStatus';
import { Button } from 'primereact/button';

const base64UrlDecode = (str: string) => {
  const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
  return JSON.parse(jsonPayload);
};

const PaymentReturn = () => {

  const [searchParams] = useSearchParams()
  const token = searchParams.get('order-token')
  const parts = token?.split('.') ?? [];
  const navigate = useNavigate();


  const decodedPayload = base64UrlDecode(parts[1]);


  const { data } = usePostOrderStatus(decodedPayload.merchantReference);

  return (
    <div className="payment-return-container">
      <div className="success-message">
        {data?.status === "done" ? <div>
          <div>Apmokėjimas sėkmingas</div>
          <div>
            Norėdami patekti į „ParkNFly“ automobilių stovėjimo aikštelę savo pasirinktu laikotarpiu, atvykę skambinkite telefonu, nurodytu ant pakeliamo aikštelės užtvaro +370 600 11 080 iš to telefono numerio, kurį nurodėte vykdydami registraciją.
            Norėdami pratęsti rezervaciją ar kilus klausimams, susisiekite su mumis telefonu +370 600 11 103 arba el.paštu info@parknfly.lt, mes mielai padėsime.
          </div>
          <Button
            label="Grįžti į pagrindinį"
            onClick={() => navigate('/')}
          />
        </div> : <div>Prašome palaukti, vykdomas mokėjimas... Mokėjimo operacija gali trukti iki 5 min. Prašome neišeiti iš puslapio.</div>}
      </div>
    </div>
  );
};

export default PaymentReturn;
