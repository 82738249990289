import React, { useEffect, useState } from "react";
import { useCreateOrder, useGetPaymentMethods } from "../../hooks/useGetPaymentMethods";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import "./PaymentMethodsModal.css"; // Import the styles

interface PaymentMethodsModalProps {
    formDataProp: {
      name: string;
      carPlate: string;
      pricePerDay: number;
      mobileNumber: string;
      email: string;
      agreeWithRules: boolean;
      parkingDate: any;
      totalPrice: number;
      companyName?: string | null;
      companyCode?: string | null;
      taxesCode?: string | null;
      companyAddress?: string | null;
      isInvoiceChecked?: boolean;
    };
    emptySpot: string;
    onClose: () => void;
  }
  

enum Countries {
    LT = "Lithuania",
    LV = "Latvia",
    EE = "Estonia",
    PL = "Poland",
    FI = "Finland",
    DE = "Germany",
}

interface PaymentMethod {
    code: string;
    logoUrl: string;
    name: string;
    supportedCurrencies: string[];
    uiPosition: number;
}

const countriesOptions = Object.keys(Countries).map((key) => ({
    label: Countries[key as keyof typeof Countries],
    value: key,
}));

const PaymentMethodsModal: React.FC<PaymentMethodsModalProps> = ({ onClose, formDataProp, emptySpot }) => {
    const { data: paymentMethodsData } = useGetPaymentMethods();
    const [selectedCountry, setSelectedCountry] = useState<string>("LT");
    const [currentPaymentMethods, setCurrentPaymentMethods] = useState<PaymentMethod[]>([]);

    useEffect(() => {
        if (paymentMethodsData && paymentMethodsData.paymentInitiation) {
            const methods = paymentMethodsData.paymentInitiation.setup[selectedCountry]?.paymentMethods || [];
            setCurrentPaymentMethods(methods);
        }
    }, [paymentMethodsData, selectedCountry]);

    const { mutate: createOrder } = useCreateOrder();

    const handleConfirm = (method: PaymentMethod) => {
        if (method) {
            const orderData = {
                ...formDataProp,
                grandTotal: formDataProp.totalPrice,
                currency: "EUR",
                locale: selectedCountry,
                preferredProvider: method.code,
                phoneNumber: formDataProp.mobileNumber,
                fromDate: formDataProp.parkingDate[0].startDate,
                endDate: formDataProp.parkingDate[0].endDate,
                email: formDataProp.email,
                name: formDataProp.name,
                pricePerDay: formDataProp.pricePerDay,
                carPlate: formDataProp.carPlate,
                emptySpot: emptySpot,
                isInvoiceChecked: formDataProp.isInvoiceChecked,
                companyName: formDataProp.companyName || null,
                companyCode: formDataProp.companyCode || null,
                taxesCode: formDataProp.taxesCode || null,
                companyAddress: formDataProp.companyAddress || null,

            };
            createOrder(orderData, {
                onSuccess: (data) => {
                    window.location.href = data.paymentUrl;
                },
                onError: (error) => {
                    console.error("Failed to create order", error);
                },
            });
        }
    };

    return (
        <Dialog
            visible={true}
            onHide={onClose}
            breakpoints={{ "960px": "75vw", "640px": "90vw" }}
            style={{ width: "50vw" }}
            header="Pasirinkite banką"
            className="payment-methods-modal"
        >
            <div className="payment-methods-container">
                <Dropdown
                    className="country-dropdown"
                    options={countriesOptions}
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.value)}
                    placeholder="Pasirinkite šalį"
                />

                <div className="payment-methods-list">
                    {currentPaymentMethods.length > 0 ? (
                        currentPaymentMethods.map((method) => (
                            <div key={method.code} className="payment-method-card" onClick={() => handleConfirm(method)}>
                                <img
                                    src={method.logoUrl}
                                    alt={method.name}
                                    className="payment-method-logo"
                                />
                            </div>
                        ))
                    ) : (
                        <div className="no-methods">No payment methods available for this country.</div>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default PaymentMethodsModal;
