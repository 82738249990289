import React from "react";
import "./Modal.css";
import { Dialog } from "primereact/dialog";

interface ModalProps {
  onClose: () => void;
  showModal: boolean;
}

const Modal = ({ showModal, onClose }: ModalProps) => {

  const modalFooter = (
    <div style={{ padding: "1rem" }}>
      <button
        className="modal-button"
        onClick={onClose}
      >
        Uždaryti
      </button>
    </div>
  );

  return (
    <Dialog
      visible={showModal}
      onHide={onClose}
      style={{ width: "80vw", maxWidth: "600px", padding: "1rem" }}
      footer={modalFooter}
    >
      <div style={{ padding: "1rem" }}>
        <h2>Paslaugos naudojimosi taisyklės</h2>
        <h3>1. Susitariančios šalys</h3>
        <p>1.1 Terminuota automobilio stovėjimo vietos nuomos sutartis tarp tiekėjo MB „Parkavimo paslauga“ ir kliento yra sudaroma tuo metu, kai klientas rezervuoja ir apmoka automobilio stovėjimo vietos užsakymą pasirinktam laikotarpiui internetiniame puslapyje.</p>
        <p>1.2 Kai užsakymas yra patvirtinamas pagal nustatytą tvarką, tiekėjas privalo klientui užtikrinti patekimą  į MB „Parkavimo Paslauga“ automobilių stovėjimo aikštelę, įsikūrusią adresu Tulpių g. 20B, Karmėlava, konkrečiai pasirinktomis datomis.</p>
        <p>1.3 Klientas automobilį gali statyti tik aikštelėje esančiose laisvose stovėjimo vietose. Automobilis turi būti pastatytas tvarkingai, laikantis baltų žymėjimo linijų, apribojančių automobilio parkavimo vietą. </p>
        <p>1.4 Kylant neaiškumams, kreiptis telefonu +370 600 11 103 (24/7) (Whatsapp programėle) arba el. paštu info@parknfly.lt </p>
        <h3>2. Užsakymas</h3>
        <p>2.1 Terminuotos automobilio parkavimo paslaugos užsakymas vykdomas internetiniame puslapyje arba telefonu.</p>
        <p>2.2 Minimalus automobilio parkavimo paslaugos užsakymas – 1 para.</p>
        <p>2.3 Rezervavimo eiga internetu (rezervuojant telefonu tiekėjui pateikiama identiška informacija):</p>
        <p>2.3.1 Pasirenkamos atvykimo bei išvykimo datos ir supildomi KONTAKTINIAI DUOMENYS:</p>
        <p>2.3.2 Įrašomi vardas ir pavardė.</p>
        <p>2.3.3 Įrašomas telefono numeris.</p>
        <p>2.3.4 Įrašomas elektroninio pašto adresas.</p>
        <p>2.3.5 Įrašomas automobilio valstybinis numeris.</p>
        <p>2.3.6 Pažymima, jog klientas susipažino su šiomis Taisyklėmis, spaudžiamas laukelis REZERVUOTI ir kliento el. paštu išsiunčiamas pranešimas apie sėkmingą užklausos gavimą.</p>
        <h3>3. Apmokėjimas</h3>
        <p>3.1 Pasirinkus apmokėjimą, klientas sistemoje yra nukreipiamas į pasirinktą internetinį banką. Sėkmingai įvykdžius apmokėjimą, klientas gauna pranešimą dėl rezervacijos patvirtinimo.</p>
        <p>3.2. Faktiniam automobilio parkavimo laikui viršyjus nurodytą rezervacijos paraiškoje, klientas privalo padengti skirtumą, atsiskaitydamas su tiekėju pagal tuo metu galiojančius tarifus. Para prasideda 00:00 ir baigiasi 23:59 (praėjus 24 valandoms.)</p>
        <h3>4. Patvirtinimas</h3>
        <p>4.1 Tiekėjas įsipareigoja patvirtinti rezervaciją ne vėliau kaip per 24 valandas po gauto apmokėjimo. Kol rezervacija nėra patvirtinta, tiekėjas vietos aikštelėje negarantuoja. Užsakovui gavus rezervaciją patvirtinantį pranešimą,  rezervacija įsigalioja ir tiekėjas garantuoja klientui automobilio parkavimo vietą. Jei reikalingas skubus patvirtinimas, klientas gali kreiptis į tiekėją telefonu +370 600 11 103.</p>
        <p>4.2 Terminuotos automobilio parkavimo paslaugos patvirtinimas išsiunčiamas klientui su rezervacijos išklotine.</p>
        <p>4.3 Klientas privalo vadovautis automobilio statymo taisyklėmis bei nurodymais, kurie pateikti rezervacijos patvirtinime.</p>
        <p>4.4 Klientas įsipareigoja likus ne mažiau kaip 24h iki numatomo atvykimo laiko informuoti tiekėją apie bet kokius pasikeitimus. Priešingu atveju, esant pasikeitusioms sąlygoms, parkavimo paslaugos suteikimas nėra užtikrinamas.</p>
        <h3>5. Kaina</h3>
        <p>5.1 Terminuotos automobilio parkavimo paslaugos mokesčio dydis priklauso nuo parkavimo vietos rezervavimo trukmės, datos, sezoniškumo bei aikštelių užpildymo.</p>
        <h3>6. Atšaukimas</h3>
        <p>6.1 Atšaukti terminuotos automobilio stovėjimo vietos nuomos rezervaciją arba keisti jos datą galima.</p>
        <p>6.2 Terminuota automobilio stovėjimo vietos nuomos rezervacija gali būti atšaukta arba jos data gali būti pakeista likus ne mažiau kaip 24 valandoms iki rezervacijos laiko pradžios.  Atšaukimas vykdomas kreipiantis telefonu +370 600 11 103 arba el. paštu info@parknfly.lt</p>
        <p>6.3 Nepasinaudojus terminuota automobilio stovėjimo vietos nuomos rezervacija pinigai grąžinami tik tuo atveju, jeigu rezervacija buvo laiku atšaukta. Pinigai už nepilnai išnaudotą arba neišnaudotą nuomos laikotarpį nėra grąžinami.</p>
        <p>6.4 Rezervuotas automobilio stovėjimo vietos nuomos terminas yra laikomas vienu neskaidomu laikotarpiu.</p>
        <p>6.5 Baudų už rezervacijos atšaukimą ar datos keitimą nėra.</p>
        <p>6.6 Atšaukus rezervaciją 6.2 punkte numatytu atveju, užsakovui grąžinama visa už rezervaciją sumokėta suma per 10 darbo dienų.</p>
        <h3>7. Naudojimasis stovėjimo aikštele</h3>
        <p>7.1 Atvykęs į aikštelę, klientas neprivalo pateikti jokių spausdintų ar elektroninių rezervaciją ar apmokėjimą patvirtinančių dokumentų.</p>
        <p>7.2 Automatinis aikštelės užtvaras atsidaro paskambinus telefono numeriu, nurodytu ant užtvaro.</p>
        <p>7.3 Skambutis atidarys vartus tik tuo atveju, jei klientas skambins iš to telefono numerio, kuris buvo nurodytas rezervacijos metu.</p>
        <p>7.4 Klientas įsipareigoja statyti automobilį į bet kurią laisvą stovėjimo vietą, atsižvelgdamas į horizontalų aikštelės žymėjimą, jei tiekėjas nenurodo kitaip. Jeigu klientas pastato automobilį nesilaikydamas nurodymų, klientas prisiima visą atsakomybę už taisyklių nesilaikymą ir galimas papildomas išlaidas.</p>
        <p>7.5 Klientas, norėdamas išvažiuoti iš aikštelės, turi paskambinti tuo pačiu numeriu, kuris nurodytas ant aikštelės užtvaro. Užtvaras atsidarys tik tuo atveju, jei klientas skambins iš to telefono numerio, kuris buvo nurodytas rezervacijos metu.</p>
        <p>7.6 Išvažiavus pro aikštelės užtvarą, nieko papildomai daryti nereikia, aikštelės užtvaras automatiškai užsidarys po kelių sekundžių.</p>
        <h3>8. Saugumas</h3>
        <p>8.1 MB „Parkavimo Paslauga" teikia automobilių parkavimo paslaugą, t.y. mokestis yra imamas už terminuotą automobilio parkavimo paslaugą.</p>
        <p>8.2 MB „Parkavimo Paslauga” už klientų automobilius bei juose paliktą turtą neatsako.</p>
        <p>8.3 Siekiant užtikrintį saugumą MB „Parkavimo Paslauga” automobilių stovėjimo aikštelėje yra įmontuotos perimetro apsaugos: aukšta skardinė tvora, aikštelės užtvaras, atidaromas tik atvykstant ir išvykstant klientams, bei vaizdo stebėjimo sistema.</p>
        <h3>9. Vaizdo stebėjimas</h3>
        <p>9.1 Vaizdo stebėjimas aikštelėje yra vykdomas siekiant užtikrinti tiekėjo nuosavybės teisės apsaugą, garantuoti valdomo turto saugumą ir neliečiamumą, paslaugų kokybę ir administravimo procesą, tiekėjo darbuotojų bei jų turto saugumą. Vaizdo stebėjimas vykdomas visą parą.</p>
        <p>9.2 Vaizdo stebėjimo metu surinkti asmens vaizdo duomenys yra saugomi 30d.</p>
        <h3>9. Asmens duomenys</h3>
        <p>10.1 Kliento pateikti duomenys yra tvarkomi ir saugomi laikantis galiojančių teisės aktų dėl asmens duomenų apsaugos.</p>
        <p>10.2 Kliento pateikti duomenys: vardas ir pavardė, elektroninio pašto adresas, telefono numeris, automobilio valstybinis numeris ir skrydžių informacija yra naudojami tik įmonės viduje tvarkant rezervacijas. </p>
        <h3>11. Skundai</h3>
        <p>11.1 Visi skundai turi būti pateikti tiesiogiai MB ,,Parkavimo paslauga“, adresu Tulpių g. 20B, Karmėlava arba el. paštu info@parknfly.lt</p>
        <h3>12. Reglamentuojantys teisės aktai</h3>
        <p>12.1 Bet koks ginčas ar nesutarimas dėl šios Sutarties ir/ar susijęs su ja, kuris per 30 (trisdešimt) dienų nuo vienos Šalies pareikšto reikalavimo dėl įsipareigojimų vykdymo neišsprendžiamas derybų keliu, turi būti sprendžiamas Lietuvos Respublikos norminių aktų nustatyta tvarka pagal teikėjo buveinės adresą.</p>
        <p>12.2 Šiai Sutarčiai yra taikomi galiojantys Lietuvos Respublikos įstatymai.</p>
      </div>
    </Dialog>
  );
};

export default Modal;
